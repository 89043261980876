/* Force disable all outline */
*:focus {
    outline: none !important;
}

.toast-message {
    margin: 0;
    font-family: 'Poppins-400';
    font-size: 14px;
}

/* To override styles from backend to match ours */
.toast-message span {
    font-family: 'Poppins-400' !important;
    font-size: 14px !important;
}

.toast-message span a {
    font-family: 'Poppins-400' !important;
    font-size: 14px !important;
}

@media (max-width: 992px) {
    .toast-message {
        font-size: 12px;
    }

    .toast-message span {
        font-size: 12px !important;
    }

    .toast-message span a {
        font-size: 12px !important;
    }
}
